import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'formik';

const SubmitForm = (props) => {
  const { submitPasswordErrorMessage, submitPasswordLoading, submitPasswordSuccessMessage } = useSelector((state) => ({
    submitPasswordErrorMessage: state.getIn(['root', 'submitPasswordErrorMessage']),
    submitPasswordLoading: state.getIn(['root', 'submitPasswordLoading']),
    submitPasswordSuccessMessage: state.getIn(['root', 'submitPasswordSuccessMessage'])
  }));
  const {
    values,
    errors,
    onSubmit,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = props;
  const { code, password, confirmPassword } = values;

  return (
    <Form className="container flex flex-col justify-center items-center w-full">
      {/* verification code section */}
      <div className="w-full px-4 flex flex-col justify-center items-center">
        <p className="text-xs py-4">
          Vous avez reçu un code de confirmation sur votre boîte mail. Merci de renseigner ce code dans le champ ci-dessous.
        </p>
        <div className="w-full flex flex-col items-center mb-6">
          <label className="text-xs font-sans mb-2 md:mb-0 w-full pl-0 md:pl-8">code</label>
          <div>
            <Field
              id="code"
              name="code"
              type="text"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark"
            />
            {errors.code && touched.code && (
              <p className="text-red-600 text-center md:text-left">{errors.code}</p>
            )}
          </div>
        </div>
      </div>
      {/* Add new password */}
      <div className="w-full px-4 flex flex-col justify-center items-center">
        <div className="w-full flex flex-col items-center mb-6">
          <label className="text-xs font-sans mb-2 md:mb-0 w-full pl-0 md:pl-8">Nouveau mot de passe</label>
          <div>
            <Field
              id="password"
              name="password"
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark"
            />
            {errors.password && touched.password && (
              <p className="text-red-600 text-center md:text-left">{errors.password}</p>
            )}
          </div>
        </div>
      </div>
      {/* confirm new password */}
      <div className="w-full px-4 flex flex-col justify-center items-center">
        <div className="w-full flex flex-col items-center mb-6">
          <label className="text-xs font-sans mb-2 md:mb-0 w-full pl-0 md:pl-8">Confirmation nouveau mot de passe</label>
          <div>
            <Field
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark"
            />
            {errors.confirmPassword && touched.confirmPassword && (
              <p className="text-red-600 text-center md:text-left">{errors.confirmPassword}</p>
            )}
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="px-8 py-1 bg-orange-dark text-sm text-white font-sans rounded"
        onclick={() => onSubmit}
        title="Confirmer"
      >Confirmer</button>
      <p className="pt-4 text-red text-sm text-center">{submitPasswordErrorMessage}</p>
      <p className="pt-4 text-green-500 text-center text-sm">{submitPasswordSuccessMessage}</p>
    </Form>
  );
};

export default SubmitForm;
import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from "@reach/router"
import * as Yup from 'yup';
import { RequestForm, SubmitForm } from '../../components/PasswordForm';
import { requestPasswordCodeLaunched, submitNewPasswordLaunched } from '../../state/reducer';
import Layout from '../../components/Layout';

const PasswordPage = ({ pageContext }) => {

  const dispatch = useDispatch();
  const { forgotPasswordStep } = useSelector((state) => ({
    forgotPasswordStep: state.getIn(['root', 'forgotPasswordStep'])
  }));
  const [email, setEmail] = useState('');

  //  Forms Data
  const RequestInitialValues = {
    email: ''
  };

  const SubmissionInitialValues = {
    code: '',
    password: '',
    confirmPassword: ''
  };

  // Forms Submitting Functions
  const requestCode = (payload) => {
    setEmail(payload.email);
    dispatch(requestPasswordCodeLaunched(payload));
  };

  const submitNewPassword = (payload) => {
    const data = { username: email.toLowerCase(), ...payload };
    dispatch(submitNewPasswordLaunched(data));
  };

  const RequestValidationSchema = Yup.object().shape({
    email: Yup
      .string()
      .email('emailNotValid')
      .required('emailRequired')
  });

  const SubmissionValidationSchema = Yup.object().shape({
    code: Yup
      .string()
      .required('code requis'),
    password: Yup
      .string()
      .min(8, "Veuillez saisir au moins 8 caractères, une lettre, un chiffre, une majuscule et un caractère spécial")
      .required('mot de passe requis'),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null], "Les mots de passe ne correspondent pas")
      .required('confirmation du mot de passe requis')
  });

  const { submitPasswordSuccessMessage } = useSelector((state) => ({
    submitPasswordSuccessMessage: state.getIn(['root', 'submitPasswordSuccessMessage'])
  }));

  if (submitPasswordSuccessMessage) {
    setTimeout(() => navigate(`/fr/login`), 5000);
  }

  const renderForms = () => {
    if (forgotPasswordStep === 1) {
      return (
        // Requesting Code
        <>
          <div>
            <div className="my-5">
              <h2 className="mt-1 block text-xs text-center tracking-tight font-semibold">
                Pas de panique ! Complétez le champ ci-dessous et nous vous enverrons par email les informations pour réinitialiser votre mot de passe.
              </h2>
            </div>
          </div>
          <Formik
            render={(props) => <RequestForm {...props} />}
            initialValues={RequestInitialValues}
            validationSchema={RequestValidationSchema}
            onSubmit={requestCode}
          />
        </>
      );
    }
    return (
      // Sending New Password
      <Formik
        render={(props) => <SubmitForm {...props} />}
        initialValues={SubmissionInitialValues}
        validationSchema={SubmissionValidationSchema}
        onSubmit={submitNewPassword}
      />
    );
  };

  return (
    <Layout
      currentPath={pageContext.currentPath}
      lang={pageContext.locale}
    >
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md sm:py-24">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 className="mt-1 block text-l tracking-tight font-bold sm:text-lg text-orange-chocolate text-center">
            Mot de passe oublié ?
          </h2>
          {renderForms()}
        </div>
      </div>
    </Layout>
  );
};

export default PasswordPage;